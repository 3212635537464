function fax() {
  this.handler = function() {
    ;
  };
  this.send = function() {
    var files  = $('input[name=file]').get(0).files,
      file     = (files.length ? files[0] : {}),
      number   = $('select[name=number]').val(),
      quality  = $('input[name=quality]:checked').val(),
      receiver = $('input[name=receiver]').val(),
      formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('quality', quality);
    formData.append('number', number);
    formData.append('receiver', receiver);
    $('#button-send').loading();
    $.send('/fax/send', formData, {
      processData: false,
      contentType: false,
      success: function(result) {
        if (!result.length) {
          makeModal({id:"modal-sm",messages:"Факс успешно отправлен!",modalDialogClass:"modal-sm"})
          resetInputFile($('input[name=file]').attr('id'));
        }
      }
    });
  };
  this.showFile = function (id) {
    document.location = '/fax/file/' + id;
  };
  this.delFile = function(id, self) {
    $.send('/fax/file/del/' + id, {}, {method: 'get', done: function(){
      $(self).closest('tr').remove();
    }});
  }
};