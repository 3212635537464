function sumbit_2auth_form() {
  var action = $("#sms_auth_form").attr('action'),
    $sms = $("#sms"),
    $sms_send_button = $("#sms_send_button"),
    $sms_val = $sms.val();
  $(".error_message").html("");

  if ($sms_val == '') {
    $(".error_message").html("Вы не ввели sms код.");
    $("#sms").css("border", "1px solid #FF0000");
    return false;
  } else {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: action,
      data: {
        'sms': $sms_val
      },
      beforeSend: function () {
        $sms_send_button.prop('disabled', true).html('Отправка...')
      },
      success: function (data) {
        if (data.status == false) {
          $sms_send_button.prop('disabled', false).html('Подтвердить')
          $(".error_message").html(data.message);
        } else {
          window.location = '/'
        }
      }
    });
  }
  return false;
}