function handleSearchFreeNumber(self, e) {
  if (e.keyCode != 13) {
    return;
  }
  $(self).closest('.tab-pane').find('.find-free-numbers').click();
}

function searchPhoneToBuy(self) {
  var $results = $(self).closest('.tab-pane').find('.search_phones_result');
  var action = $(self).attr('action');
  var q = $(self).closest('.tab-pane').find('.search_phone_number').val();
  var beauty = $(self).attr('beauty');
  $(self).loading();
  $.send(action, {q:q, beauty: beauty}, function(result){
    $results.find('.phone-buy-check:not(.hide)').remove();
    if ( ! result.phones) {
      return;
    }
    var $tpl = $results.find('.phone-buy-check.hide');
    var $t, phone;
    for (var i in result.phones) {
      phone = result.phones[i];
      $t = $tpl.clone();
      $t.removeClass('hide');
      $t.find('input').removeClass('icheck-input');
      $t.find('input').attr('data-cost', phone.price);
      $t.find('input').val(phone.id);
      $t.find('span').text(phone.number);
      $results.find('.row:first').append($t);
    }
    inputRadioCheckboxStyled();
  });
}

function buyPhoneNumbers(self) {
  var $pane = $(self).closest('.tab-pane');
  var action = $(self).attr('action');
  var phones = [];
  $pane.find('.search_phones_result input[type="checkbox"]:checked').each(function(){
    phones.push($(this).val());
  });
  $(self).loading();
  $.send(action, {
    ids: phones,
  }, function (result) {
    if (result.ok) {
      $pane.find('.find-free-numbers').click();
    }
    if (result.msg) {
      Alert(result.msg);
    }
  });
  return false;
}